@use "../../styles/colours";
@use "../../styles/sizing";
@use "../../styles/spacing";
@use "../../styles/borders";
@use "../../styles/mixins";
@use "../../styles/typography";
	
.workflow-info-block-title {
	display: flex; 
	align-items: center; 
	gap: 0.5rem;

	&.workflow-info-block-active {
		color: colours.$primary;
		font-weight: 600
	}
}

.workflow-info-block-header {
	display: flex; 
	align-items: center; 
	gap: 0.5rem;
	opacity: 0.6;
	&.not-disabled {
		opacity: 1;
	}
}

.workflow-info-block-content { 
	gap: 1.5rem;
}

.workflow-info-block-status-section { 
	width: 100px; 
	height: 60px;
	p {
		height: 20px;
	};
}

.workflow-info-block-progress-section { 
	width: 115px; 
	height: 60px; 
	p{
		height: 20px;
	};
}

.workflow-info-block-completion-section { 
	width: 150px; 
	height: 60px;
	p{
		height: 20px;
	};
}

.workflow-info-block-detail {
	height: 100%; 
	width: 100%; 
	display: flex; 
	align-items: center; 
	justify-content: start
}
						
.clickable-info-block {
	padding: 15px;
	position: relative;
	cursor: pointer;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.025);
		z-index: -1;
		border-radius: 4px;
		display: block;
	}

	&.workflow-info-block-header {
		opacity: 1;	
	}
	
	&:hover {
		&:before {
			background-color: rgba(0, 0, 0, 0.05);
		}
	}

	&.workflow-info-block-header-active {
		&:before {
			background-color: colours.$bg-neutral-blue
		}
	}
	
}