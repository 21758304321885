@use "../../../styles/colours";

.param-field {
	width: 100%;
	.material-icons {
		color: colours.$primary;
	}
	.radio-input-field {
		display: flex;
		align-items: center;
		gap: 0.25rem;
		margin-top: 0.5rem;
	}
	.radio-fields {
		gap: 0.5rem;
	}
}