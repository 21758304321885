@use "../styles/animations";
@use "../styles/colours";
@use "../styles/typography";
@use "../styles/mixins";
@use "../styles/sizing";
@use "../styles/borders";

.profile-image-container {
	@include mixins.width-and-height(sizing.$xl);
	background-color: colours.$bg-neutral-light;
	transition: animations.$transition-default;
	border-radius: 50%;
	overflow: hidden;
	object-fit: cover;
	cursor: pointer;
	outline: borders.$border-transparent;
	min-width: sizing.$xl;
	
	img {
		@include mixins.width-and-height(sizing.$xl);
		transition: animations.$transition-default;
	}

	&:hover {
		img {
			transform: scale(1.1);
		}
	}

	&.profile-initials {
		@include mixins.flex-center;
		font-size: typography.$font-size-s;
		background-color: colours.$primary;
		color: colours.$text-neutral-light;
	}

	&.profile-image-no-hover {
		cursor: default;
		pointer-events: none;
	}

}

.profile-image-focused {
	outline: borders.$border-primary;	
	img {
		transform: scale(1.1);
	}
}