@use "../../styles/colours";
@use "../../styles/sizing";
@use "../../styles/spacing";
@use "../../styles/mixins";

// Variables for colours
$colours: ('primary': colours.$bg-primary, 'positive': colours.$bg-positive, 'positive-dark': colours.$bg-positive-dark, 'warning': colours.$bg-warning, 'danger': colours.$bg-danger);

.table-action-icons {
	&.action-icons-disabled {
		.table-action-icon-wrapper {
			opacity: 0.4;
			pointer-events: none;
			cursor: default;
		}
	}
}

.table-action-icon-wrapper {
	@include mixins.flex-center;
	margin-right: spacing.$m;
	cursor: pointer;
	display: inline-flex;

	&:last-of-type, &:only-of-type {
		margin-right: 0;
	}

	&:hover {
		.table-action-icon {
			svg {
				color: colours.$text-primary;
			}
		}	
		
		@each $colour, $i in $colours {
			.table-action-icon-#{$colour} {
				svg {
					color: $i;
				}
			}
		}
	}

	&.table-action-icon-disabled {
		cursor: default;
		opacity: 0.7;

		svg {
			color: colours.$icon-grey !important;
		}

		.file-icon {
			svg {
				path {
					fill: colours.$icon-grey !important;
				}
			}
		}
	}
}

.table-action-icon {
	svg, span {
		color: colours.$icon-grey;
		width: 20px;
		height: 20px;
	}

	@each $colour, $i in $colours {
		&.table-action-icon-#{$colour} {
			svg, span {
				color: $i;
			}
		}

		&.hover-#{$colour} {
			&:hover {
				svg, span {
					color: $i;
				}
			}
		}
	}
}
