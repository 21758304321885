@use "../../styles/spacing";
@use "../../styles/typography";
@use "../../styles/mixins";
@use "../../styles/colours";
@use "../../styles/sizing";
@use "../../styles/borders";

.profile-menu-header-container {
	gap: spacing.$s;
	display: flex;
	align-items: center;
	min-width: sizing.$l;
	font-size: typography.$font-size-xs;
	
	.profile-image-container, img {
		font-size: typography.$font-size-xs;
		min-width: sizing.$l;
		@include mixins.width-and-height(sizing.$l);
	}
}

.profile-menu-details {
	width: 76%;
}
.profile-menu-option {
	width: 100%;
}
.profile-header {
	@include mixins.truncate(100%);
	margin-bottom: spacing.$xs;
	font-size: typography.$font-size-s;
	font-weight: typography.$font-weight-semi-bold;
	color: colours.$text-neutral-dark;
}

.section-header {
    margin-bottom: 0.5rem;
    font-size: 0.74rem;
    font-weight: 600;
    color: #333B5A;
}

.profile-sub-header {
	font-size: typography.$font-size-popup-menu-sub-header;
	color: colours.$text-label-grey;
	word-wrap: break-word;
}

.current-account-company {
	@include mixins.ul-reset;
	@include mixins.li-reset;
	// @include mixins.flex-align-center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: colours.$text-neutral-dark;
	// Custom padding-y to make items exactly 40px tall
	padding: 0.75rem spacing.$s;
	border-radius: borders.$radius-default;
	width: 100%;
	line-height: 1rem;
	position: relative;
}
.current-account-role {
	font-size: 0.64rem;
	line-height: 0.64rem;
    color: #ADB1BD;
    word-wrap: break-word;

}