@use "../styles/components/inputs";
@use "../styles/animations";
@use "../styles/shadows";
@use "../styles/layout/layers";
@use "../styles/colours";
@use "../styles/mixins";
@use "../styles/typography";
@use "../styles/spacing";

@mixin valueWrapperActive {
	.dropdown-chevron {
		transform: rotate(180deg);
	}
}

.placeholder {
	.dropdown-value-wrapper{
		color: inputs.$colour-text-default-placeholder !important;
		&:hover {
			color: inputs.$colour-text-default-placeholder-hover !important;
		}
	}
}

.dropdown-container {
	outline: none;

	&::placeholder{
		color: inputs.$colour-text-default-placeholder;
	}

	&.dropdown-size-small {
		.dropdown-value-wrapper {
			height: inputs.$size-height-small;
		}

		.dropdown-selection__active {
			top: 38px;
		}
	}

	&.transparent {
		.dropdown-value-wrapper {
			background-color: transparent;
			border: none;
			padding: 0;
			height: auto;

			&.dropdown-value__active {
				background-color: transparent;
				border: none;
				box-shadow: none;
			}
		}
	}
}

.dropdown-value-wrapper {
	padding: inputs.$padding-default;
	height: inputs.$size-height-default;
	transition: animations.$transition-default;
	color: inputs.$colour-text-default;
	border-radius: inputs.$border-radius-default;
	border: inputs.$border-default;
	background-color: inputs.$colour-bg-default;
	justify-content: space-between;
	display: flex;
	align-items: center;
	cursor: pointer;

	.dropdown-chevron {
		color: inputs.$colour-icon-default;
		width: inputs.$size-icon-default;
		height: inputs.$size-icon-default;
		transition: animations.$transition-default;
	}

	&.dropdown-value__active {
		background-color: inputs.$colour-bg-default-focused;
		color: inputs.$colour-text-default-focused;
		border: inputs.$border-default-focused;
		box-shadow: inputs.$shadow-default-focused;
	}
}

.dropdown-selection-list-container {
	@include mixins.hide-scrollbars;
	background-color: inputs.$colour-bg-default-focused;
	border-radius: inputs.$border-radius-default;
	box-shadow: inputs.$shadow-default-focused;
	transition: top animations.$transition-default, opacity animations.$transition-default;
	width: 100%;
} 

.dropdown-full-width {
	width: 100%;
	max-width: none;
}

.dropdown-selection-list {
	@include mixins.hide-scrollbars;
	box-shadow: shadows.$default;
	padding: 0;
	width: 100%;
	max-height: 250px;
	overflow-y: auto;
	margin: 0;

	li {
		padding: 11px 12px;
		cursor: pointer;
		
		&:hover {
			background-color: colours.$bg-neutral-blue;
			color: colours.$text-primary;
		}
	}
}

.dropdown-value__active {
	@include valueWrapperActive();
}

.header-dropdown {
	background-color: transparent;
	
	.dropdown-value-wrapper {
		background-color: transparent;
		font-size: typography.$font-size-header-3;
		font-weight: typography.$font-weight-medium;
		border: none;
		box-shadow: none;
		gap: spacing.$xs;
		padding-right: 0;

		svg {
			stroke: colours.$primary;
			width: spacing.$l;
			height: spacing.$l;
		}
	}
}