@use '../../styles/colours';
@use '../../styles/spacing';


.worflow-data-synchronisation-task {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
    max-width: 1200px;

    .missing-fund {
        padding: spacing.$s;
        color: colours.$text-danger-dark;
        background-color: colours.$bg-danger-light;
        border-radius: spacing.$xs;

        &:hover {
            opacity: 0.8;
        }
    }

    .succeeded-fund {
        padding: spacing.$s;
        color: colours.$text-positive-dark;
        background-color: colours.$bg-positive-light;
        border-radius: spacing.$xs;
    }
}

.all-tasks-completed {
    display: flex;
    gap: spacing.$s;
    align-items: center;
    font-weight: 600;
}

.workflow-publish-actions-warning-section {
    .alert-block-wrapper.alert-size-small{
        padding: 0.25rem 0.5rem;
    }
}