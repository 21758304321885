@use "../../styles/colours";
@use "../../styles/borders";
@use "../../styles/layout/layers";
@use "../../styles/typography";
@use "../../styles/mixins";
@use "../../styles/spacing";
@use "../../styles/sizing";
@use "../../styles/shadows";
@use "../../styles/animations";

.draft-note-preview-modal {
	align-self: flex-start;
	max-width: 100%;
	width: 100%;
	margin-top: -24px;
	background-color: transparent;
	display: flex;
	// justify-content: center;
	align-items: flex-start;
	flex-direction: row;
	box-shadow: none;

	.modal-close-icon {
		display: none;
	}

	.modal-content {
		padding-top: 0;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: row;
		gap: spacing.$m;
		width: 100%;
	}

	.preview-wrapper {
		box-shadow: shadows.$default;
		width: 65vw;
		min-width: 1000px;
		// max-width: 1400px;
		background-color: colours.$neutral-light;;
		border-radius: borders.$radius-default;

		&.right-sidebar-open {
			width: 81vw;
			max-width: 1852px;
		}
	}

	.preview-wrapper-inner {
		// box-shadow: shadows.$default;
		width: 1000px;
		background-color: colours.$neutral-light;;
		&.no-actions {
			padding: spacing.$m spacing.$s spacing.$m 0;
		}
	}

	.floating-sidebar {
		// box-shadow: shadows.$default;
		background-color: colours.$neutral-light;
		border-radius: borders.$radius-default;
		padding: spacing.$m spacing.$m spacing.$m spacing.$s ;
		height: auto;
		position: sticky;
		top: 0;
	}
}

.navigation-menu {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px 1rem;

	.link-container {
		width: 3rem;
		height: 3rem;
	}

	.material-symbols-outlined {
		font-size: 2rem;
		font-weight: 300;
		color: colours.$primary;

		&:hover {
			opacity: 0.6;
		}
	}
}

.preview-note-modal-header {
	p {
		font-size: 13px;
		font-weight: 500;
	}
}

.page-guide {
	background-color: colours.$input-grey;
	padding: 5px 7px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
}

.workplace-template-preview-icon {
	&.disabled {
		opacity: 0.4;
	}
}