@use "../../../styles/spacing";
@use "../../../styles/borders";
@use "../../../styles/typography";


.second-sidebar-left-container {
    display: flex;
    flex-direction: column;
    gap: spacing.$m;
    padding-top: spacing.$l;
    padding-bottom: spacing.$l;
    margin-right: spacing.$m;
    border-bottom: borders.$border-default;

    &.no-border {
        border-bottom: none;
    }
}

.second-sidebar-left-label {
    font-weight: typography.$font-weight-semi-bold;
}

.sample-group {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
}

.sample-container {
    display: flex;
    gap: 5px;
    align-items: center;
}

.colour-sample {
    width: spacing.$l;
    height: spacing.$l;
    border-radius: spacing.$xs;
    border: solid 1px #d6d6d6;
}

.sample-with-group {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.sample-content-section {
    display: flex;
    flex-direction: column;
    gap: spacing.$m;
    padding-top: spacing.$l;
    padding-bottom: spacing.$l;
    margin-right: spacing.$m;
    border-bottom: borders.$border-default;

    h2, h3 {
        padding-bottom: spacing.$m;
    }

    &.double-column {
        flex-direction: row;
        justify-content: space-between;
    }
}

.sample-banner-background {
    // position: absolute;
    height: 278px;
}

.sample-banner-with-logo {
    // padding: 0rem spacing.$l spacing.$l;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: spacing.$l spacing.$m spacing.$xl spacing.$l;
    // position: absolute;
    width: 21cm;
    top: 0;
    right: 0;
}

.brand-logo-sample-conmtainer {
    padding: 10px;
    border: solid 1px #ADB1BD;
}

.sample-banner-text {
    align-content: end;
    text-align: right;
    // padding-bottom: spacing.$m;
}

.sample-headings-two-and-three {
    display: flex;
    gap: 60px;
    align-items: end;
}

.sample-chart {
    display: flex;
    flex-direction: column;
    gap: spacing.$xl;
}

.sample-tables {
    display: flex;
    justify-content: space-between;
}

.sample-table {
    border-collapse: collapse;

    th, td {
        padding: 5pt; 
        width: 80px;
        text-align: right;

        &:first-of-type {
            width: 120px;
            text-align: left;
        }
    }
}