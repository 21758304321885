@use "../../../styles/colours";
@use "../../../styles/spacing";
@use "../../../styles/mixins";
@use "../../../styles/sizing";
@use "../../../styles/borders";

.page-grid-item {
	&.item-disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

.justify-content-start, .justify-content-between, .justify-content-end, .align-items-start, .align-items-end, .align-items-center, .flex-direction-row, .flex-direction-col  {
	display: flex;
}

.flex-direction-row {
	flex-direction: row;
}

.flex-direction-col{
	flex-direction: column;
}

.justify-content-start {
	justify-content: flex-start;
}

.justify-content-between {
	justify-content: space-between;
}

.justify-content-end {
	justify-content: flex-end;
}

.align-items-start {
	align-items: flex-start;
}

.align-items-end {
	align-items: flex-end;
}

.align-items-center {
	align-items: center;
}

.wrap {
	flex-wrap: wrap;
}

.gap-s {
	gap: sizing.$s;
}

.gap-m {
	gap: sizing.$m;
}

.gap-l {
	gap: sizing.$l;
}

.gap-xl {
	gap: sizing.$xl;
}

.second-sidebar-left {
	border-right: 1px solid colours.$border-grey;
	height: calc(100vh - 100px) !important;
	min-width: 200px;
	overflow-y: auto;
	scrollbar-width: none;
}

.rest-of-the-page-width-second-sidebar-left {
	height: calc(100vh - 100px) !important;
	overflow-y: auto;
	scrollbar-width: none;

	.toggle-on {
		font-size: 48px;
		margin-top: -4px;
		color: #0E8EFD;
		cursor: pointer;
	}
	
	.toggle-off {
		font-size: 48px;
		margin-top: -4px;
		color: #C2C4CE;
		cursor: pointer;
	}
}