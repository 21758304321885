@use "../../../styles/spacing";
@use "../../../styles/colours";

.brand-style-colour-picker { 
	position: absolute;
	z-index: 2;
	left: 10px;
	top: 10px;
	background-color: white; 
	border-radius: 14px; 
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15); 
	padding: 0.75rem;
}

.typography-colour-indicator {
	width: 1.5rem;
    height: 1.5rem;
    border-radius: spacing.$xs;
    border: solid 1px #d6d6d6;
	position: relative;
}

.github-picker {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: spacing.$xs;
	width: auto !important;
	height: auto !important;
	display: grid !important;
	grid-template-columns: repeat(4, 1fr) !important;
	gap: 3px;
	padding: spacing.$s;

	span {
		div {
			span {
				div {
					border-radius: spacing.$xs;
					border: solid 1px colours.$neutral-dark-30;
				}
			}
		}
	}
}