@use "../../../styles/typography";
@use "../../../styles/colours";
@use "../../../styles/spacing";
@use "../../../styles/borders";
@use "../../../styles/animations";

.diclosure-list-checkbox {
	div {
		display: flex;
		justify-content: center;
	}
}

.expand-sidebar-icon {
	color: colours.$neutral-dark-50;
	
	&:hover {

		color: colours.$label-grey;
	}
}
	
.disclosure-pin-icon {
	cursor: pointer;

	&.pinned-note {
		color: colours.$positive;
	}
	
	&.actively-pinning {
		color: colours.$primary;
	}
}

.foootnote-locator {
	// border: borders.$border-thick-dark-40;
	padding: spacing.$xs 0;
	border-radius: 50%;
	text-align: center;
	font-size: 20px;
	font-weight: 500;
	color: colours.$primary;
	width: spacing.$l;
    height: spacing.$l;
	cursor: pointer;

	// &:hover {
	// 	border-color: colours.$primary;
	// 	color: colours.$primary;
	// }
}