@use "../../../styles/colours.scss" as colours;

.deleted-personnel {
	background-color: colours.$danger-light !important;
}

.delete-icon {
	color: colours.$neutral-dark-30;
	cursor: pointer
	;
	&:hover {
		color: colours.$danger;
	}
}

.fund-page-expanded-key-person {
	td {
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
}