#master-grid {
	grid-template-rows: 1fr;
	grid-template-columns: 4rem auto 1fr 19rem 4rem;
	height: calc(100vh - 3rem);
	width: 100vw;
	display: grid;
	grid-gap: 0;
	margin-top: 3rem;
	grid-template-areas:
		// "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
		"sidebar-left page-content page-content page-content page-content";

	&.grid-sidebar-ext-left-active {
		grid-template-areas:
			// "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
			"sidebar-left sidebar-ext-left page-content page-content page-content";
	}

	&.grid-sidebar-right-active {
		grid-template-areas:
			// "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
			"sidebar-left page-content page-content page-content sidebar-right";
	}

	&.grid-sidebar-ext-right-active.grid-sidebar-right-active {
		grid-template-areas:
			// "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
			"sidebar-left page-content page-content sidebar-ext-right sidebar-right";
	}

	&.grid-sidebar-ext-left-active.grid-sidebar-right-active {
		grid-template-areas:
			// "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
			"sidebar-left sidebar-ext-left page-content page-content sidebar-right";
	}

	&.grid-sidebar-ext-left-active.grid-sidebar-right-active.grid-sidebar-ext-right-active {
		grid-template-areas:
			// "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
			"sidebar-left sidebar-ext-left page-content sidebar-ext-right sidebar-right";
	}

	// Alert banner active
	&.grid-alert-banner-active {
		.alert-banner-wrapper {
			display: flex;
		}

		grid-template-rows: 3rem 2.5rem 1fr;
		grid-template-areas: // "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
		"alert-banner alert-banner alert-banner alert-banner alert-banner"
		"sidebar-left page-content page-content page-content page-content";

		&.grid-sidebar-ext-left-active {
			grid-template-areas:
				// "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
				"alert-banner alert-banner alert-banner alert-banner alert-banner"
				"sidebar-left sidebar-ext-left page-content page-content page-content";
		}

		&.grid-sidebar-right-active {
			grid-template-areas:
				// "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
				"alert-banner alert-banner alert-banner alert-banner alert-banner"
				"sidebar-left page-content page-content page-content sidebar-right";
		}

		&.grid-sidebar-ext-right-active.grid-sidebar-right-active {
			grid-template-areas:
				// "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
				"alert-banner alert-banner alert-banner alert-banner alert-banner"
				"sidebar-left page-content page-content sidebar-ext-right sidebar-right";
		}

		&.grid-sidebar-ext-left-active.grid-sidebar-right-active {
			grid-template-areas:
				// "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
				"alert-banner alert-banner alert-banner alert-banner alert-banner"
				"sidebar-left sidebar-ext-left page-content page-content sidebar-right";
		}

		&.grid-sidebar-ext-left-active.grid-sidebar-right-active.grid-sidebar-ext-right-active {
			grid-template-areas:
				// "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
				" alert-banner alert-banner alert-banner alert-banner"
				"sidebar-left sidebar-ext-left page-content sidebar-ext-right sidebar-right";
		}
	}

	// top bar extension open
	&.grid-topbar-ext-active {
		grid-template-rows: 5rem calc(100% - 5rem);
		grid-template-areas:
			"sidebar-left topbar-extension topbar-extension topbar-extension topbar-extension"
			"sidebar-left page-content page-content page-content page-content";

		&.grid-sidebar-right-active {
			grid-template-areas:
				// "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
				"sidebar-left topbar-extension topbar-extension topbar-extension sidebar-right"
				"sidebar-left page-content page-content page-content sidebar-right";
		}

		&.grid-sidebar-ext-right-active.grid-sidebar-right-active {
			grid-template-areas:
				// "top-nav-bar top-nav-bar top-nav-bar top-nav-bar top-nav-bar"
				"sidebar-left topbar-extension topbar-extension sidebar-ext-right sidebar-right"
				"sidebar-left page-content page-content sidebar-ext-right sidebar-right";
		}

		&.grid-sidebar-ext-left-active {
			grid-template-areas:
				"sidebar-left sidebar-ext-left topbar-extension topbar-extension topbar-extension"
				"sidebar-left sidebar-ext-left page-content page-content page-content";
		}
	}

	// right extension extra extended
	&.right-m {
		grid-template-columns: 4rem auto 1fr 30rem 4rem;
	}

	&.right-l {
		grid-template-columns: 4rem auto 1fr 50rem 4rem;
	}
}