@use "../../../styles/colours";
@use "../../../styles/spacing";
@use "../../../styles/mixins";
@use "../../../styles/borders";
@use "../../../styles/layout/layers";
@use "../../../styles/typography";

.page-content-outer {
	&.hide-scrollbar {
		@include mixins.hide-scrollbars;
	}

	background: colours.$bg-neutral-light;
	grid-area: page-content;
	padding: 0 spacing.$m 0 spacing.$l;
	padding-bottom: 5rem;
	height: calc(100vh - 3rem);
	overflow-y: auto;
	width: 100%;

	// Grid styles
	column-gap: spacing.$l;
	display: grid;
	grid-template-rows: 5rem calc(100vh - 8rem);
	grid-template-columns: repeat(12, minmax(0, 1fr));
	grid-template-areas: 
	"page-banner page-banner page-banner page-banner page-banner page-banner page-banner page-banner page-banner page-banner page-banner page-banner"
	"page-content page-content page-content page-content page-content page-content page-content page-content page-content page-content page-content page-content";

	.page-content-body-grid {
		grid-area: page-content;
		padding-top: spacing.$xl;
	}

	&.no-banner {
		grid-template-rows: calc(100vh - 3rem);
		grid-template-areas:
			"page-content page-content page-content page-content page-content page-content page-content page-content page-content page-content page-content page-content";
	}

	&.fixed-banner {
		overflow-y: hidden;

		.page-content-body-grid {
			&.hide-scrollbar {
				@include mixins.hide-scrollbars;
			}
			padding-bottom: 5rem;
			overflow-y: auto;

			&.grid-split-scroll {
				padding-bottom: 0;
			}
		}
	}

	&.no-banner {
		.page-content-body-grid {
			&.hide-scrollbar {
				@include mixins.hide-scrollbars;
			}
			padding-top: spacing.$l;
			overflow-y: auto;
		}
	}
	&.no-overflown-y {
		overflow-y: hidden;

		.page-content-body-grid {
			&.no-overflown-y {
				overflow-y: hidden;
			}
		}
	}
}

.page-content-body-grid {
	column-gap: spacing.$l;
	row-gap: spacing.$l;
	z-index: layers.$layer-1;
	display: grid;
	grid-auto-rows: min-content;
	grid-template-columns: repeat(12, minmax(0, 1fr));

	&.hide-scrollbar {
		@include mixins.hide-scrollbars;
	}

	&.grid-split-scroll {
		grid-template-rows: 1fr;
		padding-top: 0;

		.page-grid-item-container {
			&.hide-scrollbar {
				@include mixins.hide-scrollbars;
			}
			height: 100%;
			overflow: auto;
			padding-bottom: 3rem;
			padding-top: spacing.$l;

			&.second-sidebar-left {
				padding-top: 0 !important;
			}

			&.rest-of-the-page-width-second-sidebar-left {
				padding-top: 0 !important;
			}
		}
	}

	&.grid-divider-top {
		height: 100%;
		border-top: borders.$border-default;
		padding-top: spacing.$l;
	}
	&.grid-divider-right {
		height: 100%;
		border-right: borders.$border-default;
		padding-right: spacing.$l;
	}
	&.grid-divider-bottom {
		height: 100%;
		border-bottom: borders.$border-default;
		padding-bottom: spacing.$l;
	}
	&.grid-divider-left {
		height: 100%;
		border-left: borders.$border-default;
		padding-left: spacing.$l;
	}

	&.row-gap-xs {
		row-gap: spacing.$xs;
	}
	&.row-gap-s {
		row-gap: spacing.$s;
	}
	&.row-gap-m {
		row-gap: spacing.$m;
	}
	&.row-gap-xl {
		row-gap: spacing.$xl;
	}
	&.row-gap-xxl {
		row-gap: spacing.$xxl;
	}
	&.row-gap-none {
		row-gap: spacing.$none;
	}

	&.column-gap-xs {
		column-gap: spacing.$xs;
	}
	&.column-gap-s {
		column-gap: spacing.$s;
	}
	&.column-gap-m {
		column-gap: spacing.$m;
	}
	&.column-gap-xl {
		column-gap: spacing.$xl;
	}
}

.table-title {
	font-weight: typography.$font-weight-semi-bold;
	padding-bottom: spacing.$l;
	padding-top: spacing.$m;
}

// .page-content-banner-fixed {
// 	position: fixed;
//     top: 3rem;
// 	z-index: 20;
//     width: 100%;
//     padding-bottom: spacing.$l;
// 	padding-top: spacing.$l;
//     background: #fff;
// 	border-bottom: borders.$border-default;
// }