@use "../../../styles/typography";
@use "../../../styles/colours";
@use "../../../styles/borders";
@use "../../../styles/spacing";
@use "../../../styles/shadows";
@use "../../../styles/animations";
@use "../../../styles/mixins";
@use "../../../styles/sizing";


.sidebar-extension-wrapper {
    @include mixins.hide-scrollbars;
    padding: spacing.$l spacing.$m;
    background-color: colours.$bg-neutral-light;
    grid-area: sidebar-ext-left;
    height: calc(100vh - 3rem);
    width: 19rem;
    left: 0;
    position: relative;
    overflow-y: auto;
    overflow-x: visible;
    z-index: 1;
    transition: 600ms;
    opacity: 1;

    &.collapsed {
        left: -19rem;
        padding: spacing.$l 0;
        width: 0;
        overflow: hidden;

        .sidebar-section {
            opacity: 0;
        }

        .sidebar-footer {
            width: 0;
        }
        
        .collapse-button-wrapper {
            opacity: 1;
        }
    }

    .sidebar-section {
        opacity: 1;
        transition: 1s;

        &:first-of-type {
            padding-top: 0;

            &.pt-xl {
                padding-top: 2rem;
            }
        }
    }

    &.sidebar-extension-wrapper-with-footer {
        padding-bottom: 132px
    }

    &.position-left {
        grid-area: sidebar-ext-left;
    }

    &.position-right {
        grid-area: sidebar-ext-right;
        position: absolute;
        right: 4rem;
        left: auto;
    }

    &.sidebar-extension-wrapper-no-padding {
        padding: spacing.$l 0;

        .sidebar-section {
            padding: spacing.$xl spacing.$m;

            &:first-of-type {
                padding-top: 0;
            }
        }
    }

    &.sidebar-extension-wrapper-shadow {
       box-shadow: shadows.$light; 
    }

    &.sidebar-extension-slide-in {
        animation: animations.$transition-fade-in-left;
    }

    &.sidebar-extension-slide-out {
        animation: animations.$transition-fade-in-left;
        animation-direction: reverse;
    }

    &.sidebar-extension-fixed-header {
        padding-top: 66px;

        .sidebar-section-header {
            position: fixed;
            top: 24px;
        }

        .sidebar-section-content {
            @include mixins.hide-scrollbars();
            overflow: auto;
            height: calc(100vh - 114px);
        }
    }

    &.right-ext-s {
        width: 19rem;
    }

    &.right-ext-m {
        width: 30rem;
    }

    &.right-ext-l {
        width: 50rem;
    }
}