@use "../../styles/colours";
@use "../../styles/sizing";
@use "../../styles/spacing";
@use "../../styles/borders";
@use "../../styles/mixins";
@use "../../styles/typography";

.workflow-compliance-step-row {
	td {
		height: 50px;
	}
}

.workflow-cell-align-right {
	text-align: right;
}

.fund-approval-row {

}

.table-wrapper { table {td {&.fund-approval-row-cell {
	padding: 0 !important;
	height: 100%;
	width: 100%;
	background-color: colours.$neutral-blue;
	.inner-cell {
		background-color: transparent;
		display: flex; 
		align-items: center; 
		height: 100%;
		width: 100%;
		padding: 0.5rem 0;
		
	}
}}}}

.fund-row-expanded {
	td{
		background-color: colours.$neutral-blue !important;
	}
}

.table-wrapper table {
	tr{
		&.unselectable-fund-action-row  {
			&:hover {

				td {
					background-color: #fff;
				}
			}
		}
	}
}

.workflow-step-expansion {
	.table-wrapper table tr.unselectable-fund-action-row:hover td {
		background-color: #F4F6FA;
	}
	.table-wrapper table tr.unselectable-fund-action-row td {
		background-color: #F4F6FA;
	}
	.table-wrapper table tr td {
		background-color: #F4F6FA;
	}
	.table-wrapper table tr {
		&:not(.unselectable-fund-action-row) {
			&:hover {
				td {
					background-color: #F4F6FA;
					&.compliance-action-fund-name{ 
						font-weight:600
					}
					&.action-row-hoverable-chevron {
						svg {
							stroke: colours.$primary;
						}
					}
				}
			}
		}
		background-color: #F4F6FA;
	}
	.expand-inner {
		padding: 0 2rem !important;
	}
}
.workflow-compliance-step-row {
}