@use "../../styles/components/inputs";
@use "../../styles/typography";
@use "../../styles/animations";
@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/mixins";

.text-input-wrapper {
	.text-input-helper-text {
		font-size: typography.$font-size-s;
		margin-top: spacing.$xs;
		color: colours.$text-neutral-dark;
		animation: animations.$transition-fade-up;
	}

	&.input-full-width {
		width: 100%;
	}

	&.text-field-width-s {
		width: 176px;
	}
	
	&.text-field-width-m {
		width: 240px;
	}
	
	&.text-field-width-l {
		width: 312px;
	}

	&.icon-active {
		position: relative;

		input {
			padding-left: spacing.$xl;
		}

		.text-field-icon-wrapper {
			@include mixins.width-and-height(inputs.$size-icon-default);
			left: spacing.$s;
			bottom: 0.8rem;
			position: absolute;

			svg {
				color: inputs.$colour-icon-default;
				width: 100%;
				height: 100%;
			}
		}

		&:focus-within {
			.text-field-icon-wrapper {
				svg {
					color: inputs.$colour-icon-default-focused;
				}
			}
		}
	}

	&.clear-icon-active {
		input {
			padding-right: spacing.$l;
		}

		.clear-icon {
			@include mixins.width-and-height(inputs.$size-icon-default);
			right: spacing.$s;
			bottom: 0.8rem;
			position: absolute;
			color: inputs.$colour-icon-default;
			cursor: pointer;

			&:hover {
				color: inputs.$colour-icon-default-focused;
			}
		}
	}

	&.text-input-small {
		&.icon-active, &.clear-icon-active {
			.text-field-icon-wrapper, .clear-icon {
				bottom: 0.5rem;
			}
		}
	}
}

::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

.text-input {
	background-color: inputs.$colour-bg-default;
	border: inputs.$border-default;
	border-radius: inputs.$border-radius-default;
	color: inputs.$colour-text-default;
	font-size: inputs.$font-size-default;
	padding: inputs.$padding-default;
	height: inputs.$size-height-default;
	font-family: typography.$font-family-primary;
	transition: animations.$transition-default;
	width: 100%;
	outline: none;

	&.input-size-small {
		height: inputs.$size-height-small;
	}

	&.input-variant-light {
		color: inputs.$colour-text-default-focused;
		background-color: inputs.$colour-bg-default-focused;
		border: inputs.$border-light;
	}

	&.input-variant-bordered-light {
		color: inputs.$colour-text-default-focused;
		background-color: inputs.$colour-bg-default-focused;
		border:  1px solid #CDD1DB;
	}

	.text-input-icon{
		color: inputs.$colour-icon-default;
		font-size: inputs.$size-icon-default;
		margin: inputs.$margin-icon-default;
	}
	
	&::placeholder{
		color: inputs.$colour-text-default-placeholder;
		transition: animations.$transition-default;
	}
	
	&:hover {			
		&::placeholder{
			color: inputs.$colour-text-default-placeholder-hover;			
		}
	}
	
	&:focus{
		color: inputs.$colour-text-default-focused;
		background-color: inputs.$colour-bg-default-focused;
		border: inputs.$border-default-focused;
		box-shadow: inputs.$shadow-default-focused;
		
		&::placeholder{
			color: inputs.$colour-text-default-placeholder-focused;	
		}
		
		.text-input-icon{
			color: inputs.$colour-icon-default-focused;
		}
	}

	&:focus-visible{
		outline: none;
		border: inputs.$border-default-focused;
		box-shadow: inputs.$shadow-default-focused;
	}

	&::selection {
		background-color: rgba(colours.$bg-primary, 0.6);
	}

	&:disabled {
		background-color: inputs.$colour-bg-default-disabled;
		color: inputs.$colour-text-default-disabled;
		border: inputs.$border-disabled;

		&:hover {
			&::placeholder{
				color: inputs.$colour-text-default-placeholder;
			}
		}

	}
}

// Validation

.text-input-wrapper-invalid {
	.text-input {
		background-color: inputs.$colour-bg-invalid;
		border: inputs.$border-invalid;
		color: inputs.$colour-text-invalid;

		&::placeholder{
			color: rgba(inputs.$colour-text-invalid, 0.3);
		}

		&:focus{
			color: inputs.$colour-text-default-focused;
			background-color: inputs.$colour-bg-default-focused;
			border: inputs.$border-invalid-focused;
			box-shadow: inputs.$shadow-default-focused;
			
			&::placeholder{
				color: inputs.$colour-text-default-placeholder-focused;	
			}
			
			.text-input-icon{
				color: inputs.$colour-icon-default-focused;
			}
		}

		&::selection {
			background-color: rgba(colours.$bg-danger, 0.6);
		}
	}

	.text-input-helper-text {
		color: colours.$text-danger-dark;
		animation: animations.$shake;
	}
}