@use "../../styles/components/buttons";
@use "../../styles/borders";
@use "../../styles/colours";
@use "../../styles/layout/layers";
@use "../../styles/animations";
@use "../../styles/mixins";
@use "../../styles/sizing";

.collapse-button-wrapper {
	@include mixins.width-and-height(sizing.$l);
	@include mixins.flex-center();
	background-color: colours.$accent-2;
	z-index: layers.$layer-8;
	transition: 690ms;
	border-radius: 6px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	margin-left: auto;
	cursor: pointer;
	transform: translateY(-3.5rem) translateX(16px);

	svg {
		transition: animations.$transition-default;
	}

	&.outer-collapse {
		border-radius: 0;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		position: fixed;
		top: 72px;
		left: 0;
		opacity: 1;
		transform:  translateY(-16px) translateX(0);
	}

	&.collapsed {
		left: 63px;
		transition: 400ms;

		svg {
			transform: rotate(180deg);
		}
	}
}
