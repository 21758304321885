@use "../../../styles/colours";
@use "../../../styles/spacing";


.risk-label {
    padding-bottom: 10px;

    &.static-label {
        color: colours.$neutral-dark-40;
        font-size: 0.85rem;
        font-weight: 400;
    }
}
.risk-cubes {
    display: flex;
    gap: 10px;
}

.risk-cube {
    background-color: colours.$neutral-blue;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: colours.$neutral-dark-60;

    &.active {
        background-color: colours.$primary;
        color: colours.$neutral-light;
    }

    &.editing {
        cursor: pointer;
    }
}

.detail-text {
    line-height: 18px;
}

.box-style {
    padding: 0.5rem;
    background-color: colours.$input-grey;
    border-radius: spacing.$xs
}

.full-width {
    width: 100%;
}

.detail-text-box {
    height: fit-content;
}

.no-selected-box {
    opacity: 0.5;
}