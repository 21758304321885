@use "../../../styles//colours" as colours;


.data-manager-page-form-wrapper {
	.detail-field-wrapper {
		width: 245px;
		cursor: pointer;
	}

	.button-base {
		height: 40px;
	}
}

//this does nothing - but if someone smarter than I can figure out how to make it work, that would be great, Cheers Andy
.search-select-field__option {
	cursor: pointer;
}

.empty-upload-table-message {
	font-size: 20px;
	font-weight: medium;
	line-height: 24px;
	color: colours.$neutral-dark;

}

.data-processing-table-row {

	// styles for rows hover and selected states

	// &.expanded {
	// 	td {
	// 		background-color: colours.$neutral-blue;
	// 	}
	// }
	td {
		vertical-align: middle;
		// cursor: pointer;
		// &.selected {
		// 	background-color: colours.$neutral-blue;
		// }
	}
}

.data-processing-table-row-expansion {
	background-color: rgba(colours.$neutral-blue, 0.5);

	td {
		padding: 1rem;
		background-color: transparent !important;
	}
}

.upload-table-status-message {
	margin-bottom: 1rem;

	p {
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
		padding: 0.25rem 0.5rem;
	}
}

.upload-table-error-message {
	.error-heading {
		padding-left: 0.25rem;
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
		color: colours.$danger-dark;
		margin-bottom: 1rem;

		.subheading {
			font-weight: 400;
			margin-left: 0.25rem
		}
	}

	p {
		font-size: 14px;
		line-height: 24px;
		font-weight: 500;
		color: colours.$danger-dark;
		background-color: colours.$danger-light ;
		border-radius: 4px;
		padding: 0.25rem 0.5rem;
	}
}

.downlodable {
	text-decoration: underline;
	cursor: pointer;

	&:hover {
		color: colours.$text-primary;
	}
}