@use "../../../styles/colours";
@use "../../../styles/spacing";

.publish-action-expansion-approvers {
    display: flex;
    align-items: center;
    gap: spacing.$s;
    height: spacing.$xl;
    align-items: center;

    .number-of-approvers {
        display: flex;
        align-items: center;

        input {
            height: spacing.$xl;
            width: 50px;
            border: none;
            border-radius: spacing.$xs;
            padding: spacing.$xs;
        }

        .input-icons {
            display: flex;
            flex-direction: column;
            margin-left: -20px;
            cursor: pointer;
        }
    }
}

.publish-action-expansion-role {
    display: grid;
    grid-template-columns: 110px auto;
    align-items: center;
    gap: spacing.$s;
    width: 100%;

    .popup-outer {

        .dropdown-container {
            width: 100%;
    
            .dropdown-value-wrapper {
                background-color: colours.$neutral-light;
            }
        }
    }
}

.publish-action-disclose-file-list {
    .publish-action-disclose-file-name {
        display: flex;
        gap: spacing.$s;
        align-items: center;
        height: spacing.$xxl;
        width: 100%;
        border-bottom: solid 1 spacing.$xs colours.$border-grey;
    
        &:last-of-type {
            border-bottom: none;
        }
    }
}

.custom-publish-actions-options-selector {
    .action-field-label {
        margin-bottom: spacing.$m;
    }

    .search-select-field {
        .search-select-field__control {
            background-color: colours.$neutral-blue;
        }
    }
}

.expand-content-row {
    .expand-inner {

        .custom-publish-actions-options-selector {
            .action-field-label {
                margin-bottom: spacing.$m;
            }
        
            .search-select-field {
                .search-select-field__control {
                    background-color: #fff;
                }
            }
        }

    }
}

.param-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: spacing.$m;
    .search-select-field .search-select-field__control{
        background-color: #fff;
    }
    .invalid-select-field {
        &.search-select-field {
            .search-select-field__control {
                background-color: colours.$danger-light;
            }
        }    
    }
}

.template-settings-list-invalid-row {
   td{
       background-color: colours.$danger-light !important;
    //    color: colours.$danger-dark !important;
   } 
}

.checkbox-reduced-margin {
    .checkbox-field-wrapper {
        margin-right: 0.5rem;
    }
}

.not-editable-template-settings-row {
    .checkbox-field-wrapper {
        opacity: 0.5
    }
}