@use "../../styles/colours";


.pulse-dot-container {
	transform: translateY(-2px);
	position: relative;
	background: #45453f;
  }
  
  .pulse-dot-dot {
	width: 5px;
	height: 5px;
	// border: 5px solid colours.$primary;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 20px;
	background-color: colours.$primary;
	z-index: 10;
	position: absolute;
  }
  
  .pulse-dot-pulse {
	border: 4px solid colours.$accent-1;
	background: transparent;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	border-radius: 60px;
	height: 12px;
	width: 12px;
	-webkit-animation: smallpulse 3s ease-out;
	-moz-animation: smallpulse 3s ease-out;
	animation: smallpulse 3s ease-out;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	position: absolute;
	top: -3.5px;
	left: -3.5px;
	z-index: 1;
	opacity: 0;
  }

  .big-pulse-dot-pulse {
	border: 2px solid colours.$accent-1;
	background: transparent;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	border-radius: 60px;
	height:24px;
	width:24px;
	-webkit-animation: bigpulse ease-out 3s 0.5s;
	-moz-animation: bigpulse ease-out 3s 0.5s;
	animation: bigpulse ease-out 3s 0.5s;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	position: absolute;
	top: -9.5px;
	left: -9.5px;
	z-index: 1;
	opacity: 0;
  }
  
  @keyframes smallpulse {
   0% {
	  -moz-transform: scale(0);
	  -webkit-transform: scale(0);
	  opacity: 0.0;
   }
   25% {
	  -moz-transform: scale(0.2);	  
	  -webkit-transform: scale(0.2);
	  opacity: 0.1;
   }
   50% {
	  -moz-transform: scale(0.5);	  
	  -webkit-transform: scale(0.5);
	  opacity: 0.3;
   }
   75% {
	  -moz-transform: scale(0.75);	  
	  -webkit-transform: scale(0.8);
	  opacity: 0.5;
   }
   100% {
	  -moz-transform: scale(1);	  
	  -webkit-transform: scale(1);
	  opacity: 0.0;
   }
  }

  @keyframes bigpulse {
	0% {
	   -moz-transform: scale(0);
	   -webkit-transform: scale(0);
	   opacity: 0.0;
	}
	25% {
	   -moz-transform: scale(0.1);	  
	   -webkit-transform: scale(0.1);
	   opacity: 0.1;
	}
	50% {
	   -moz-transform: scale(0.2);	  
	   -webkit-transform: scale(0.2);
	   opacity: 0.3;
	}
	75% {
	   -moz-transform: scale(0.5);	  
	   -webkit-transform: scale(0.5);
	   opacity: 0.5;
	}
	100% {
	   -moz-transform: scale(0.8);	  
	   -webkit-transform: scale(0.8);
	   opacity: 0.0;
	}
   }