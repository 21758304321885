// https://3skye.atlassian.net/wiki/spaces/3C/pages/1379401729/Design+Library+Research+Editor#Colours
// https://3skye.atlassian.net/wiki/spaces/3C/pages/1397391402/Research+CRM+-+Design+Tokens
// @use "./DesignTokens.scss" as *;
@use "./colours.scss";
@use "./borders.scss";
@use "./shadows.scss";
@use "./sizing.scss";
@use "./mixins.scss";
@use "./spacing.scss";
@use "./animations.scss";
@use "./typography.scss";
@use "./layout/layers.scss";
@use "./layout/breakpoints.scss";

// Objects for @each loops
$sizes: 'none' 0, 'auto' auto, 'xs' spacing.$xs,'s' spacing.$s, 'm' spacing.$m, 'l' spacing.$l, 'xl' spacing.$xl, 'xxl' spacing.$xxl;
$selectors: 'm' 'margin', 'p' 'padding', 'gap' 'gap';
$sides: '' '', 't' '-top', 'r' '-right', 'b' '-bottom', 'l' '-left';
$breakpoints: 'xs' breakpoints.$breakpoint-xs, 'sm' breakpoints.$breakpoint-sm, 'md' breakpoints.$breakpoint-md, 'lg' breakpoints.$breakpoint-lg, 'xl' breakpoints.$breakpoint-xl;
$tenths: '01' 0.1, '02' 0.2, '03' 0.3, '04' 0.4, '05' 0.5, '06' 0.6, '07' 0.7, '08' 0.8, '09' 0.9, '10' 1;

// Animations
.transition-default {
	transition: animations.$transition-default;
}
.transition-fade-in {
	animation: animations.$transition-fade-in;
}
.transition-fade-in-slow {
	animation: animations.$transition-fade-in-slow;
}
.transition-fade-out {
	transition: animations.$transition-fade-out;
}
.transition-hover {
	transition: animations.$transition-hover;
}
.transition-fade-up {
	animation: animations.$transition-fade-up;
}
.transition-fade-up-slow {
	animation: animations.$transition-fade-up-slow;
}

// Borders
@each $side in $sides {
	.border-default#{nth($side, 2)} {
		border#{nth($side, 2)}: borders.$width-default borders.$style-default borders.$colour-default;
	}
	.border-thick#{nth($side, 2)} {
		border#{nth($side, 2)}: borders.$width-thick borders.$style-default borders.$colour-default;
	}
	.border-default-highlight#{nth($side, 2)} {
		border#{nth($side, 2)}: borders.$width-default borders.$style-default borders.$colour-primary;
	}
	.border-thick-highlight#{nth($side, 2)} {
		border#{nth($side, 2)}: borders.$width-thick borders.$style-default borders.$colour-default;
	}
	.border-radius-rounded#{nth($side, 2)} {
		border#{nth($side, 2)}: borders.$radius-default;
	}
}

// flex
.flex-center {
	@include mixins.flex-center;
}

.flex-align-center {
	@include mixins.flex-align-center;
}

.flex-justify-center {
	@include mixins.flex-justify-center;
}
.pointer {
	cursor: pointer;
}
.cursor-default {
	cursor: default;
}
.cursor-pointer {
	cursor: pointer;
}
.cursor-grab {
	cursor: grab;
}
.cursor-grabbing {
	cursor: grabbing;
}

// Typography
.body-small {
	font-size: typography.$font-size-s;
	line-height: typography.$line-height-s;
	margin-bottom: spacing.$l;
}
.p-medium {
    font-size: typography.$font-size-m;
	line-height: typography.$line-height-m;
	margin-bottom: spacing.$l;
}
.p-large {
	font-size: typography.$font-size-l;
	line-height: typography.$line-height-m;
	margin-bottom: spacing.$l;
}
.header-1 {
	font-size: typography.$font-size-header-1;
	font-weight: typography.$font-weight-medium;
    margin-bottom: spacing.$xl;
}
.header-2 {
	font-size: typography.$font-size-header-2;
	font-weight: typography.$font-weight-medium;
    margin-bottom: spacing.$xl;
}
.header-3 {
	font-size: typography.$font-size-header-3;
	font-weight: typography.$font-weight-medium;
    margin-bottom: spacing.$xl;
}
.header-4 {
	font-size: typography.$font-size-header-4;
	font-weight: typography.$font-weight-medium;
    margin-bottom: spacing.$xl;
}
.header-5 {
	font-size: typography.$font-size-header-5;
	font-weight: typography.$font-weight-medium;
    margin-bottom: spacing.$xl;
}
.header-6 {
	font-size: typography.$font-size-header-6;
	font-weight: typography.$font-weight-semi-bold;
    margin-bottom: spacing.$xl;
}
.label-input {
	font-size: typography.$font-size-input-label;
	color: colours.$text-neutral-dark;
	font-weight: typography.$font-weight-regular;
	margin-bottom: 10px;
	display: inline-block;
}
.extension-label {
	font-size: typography.$font-size-s;
	color: colours.$neutral-dark-40;
	font-weight: typography.$font-weight-medium;
}
.dropdown-extension-label {
	font-weight: typography.$font-weight-regular;
	color: colours.$neutral-dark-50;
	margin-left: spacing.$s;
	font-size: 12.6px;
}

$colours: (
    "neutral-light": colours.$neutral-light,
    "neutral-blue": colours.$neutral-blue,
    "neutral-dark": colours.$neutral-dark,
    "neutral-dark-10": colours.$neutral-dark-10,
    "neutral-dark-20": colours.$neutral-dark-20,
    "neutral-dark-30": colours.$neutral-dark-30,
    "neutral-dark-40": colours.$neutral-dark-40,
    "neutral-dark-50": colours.$neutral-dark-50,
    "neutral-dark-60": colours.$neutral-dark-60,
    "neutral-dark-70": colours.$neutral-dark-70,
    "neutral-dark-80": colours.$neutral-dark-80,
    "neutral-dark-90": colours.$neutral-dark-90,
    "primary": colours.$primary,
    "accent-1": colours.$accent-1,
    "accent-2": colours.$accent-2,
    "positive": colours.$positive,
    "positive-dark": colours.$positive-dark,
    "positive-light": colours.$positive-light,
    "warning": colours.$warning,
    "warning-light": colours.$warning-light,
    "warning-dark": colours.$warning-dark,
    "danger": colours.$danger,
    "danger-light": colours.$danger-light,
    "danger-dark": colours.$danger-dark,
    "purple": colours.$purple,
    "label-grey": colours.$label-grey,
);

$fontSizes: (
    "body-xs": typography.$font-size-xs,
    "body-s": typography.$font-size-s,
    "body-m": typography.$font-size-m,
    "body-l": typography.$font-size-l,
    "header-1": typography.$font-size-header-1,
    "header-2": typography.$font-size-header-2,
    "header-3": typography.$font-size-header-3,
    "header-4": typography.$font-size-header-4,
    "header-5": typography.$font-size-header-5,
    "header-6": typography.$font-size-header-6,
    "input-label": typography.$font-size-input-label,
    "field-label": typography.$font-size-field-label,
);

$fontWeights: (
    "regular": typography.$font-weight-regular,
    "medium": typography.$font-weight-medium,
    "semi-bold": typography.$font-weight-semi-bold,
);

$shadows: (
    "shadow-light": shadows.$light,
    "shadow-default": shadows.$default,
    "shadow-dark": shadows.$dark
);

$layers: (
    "layer-1": layers.$layer-1,
    "layer-2": layers.$layer-2,
    "layer-3": layers.$layer-3,
    "layer-4": layers.$layer-4,
    "layer-5": layers.$layer-5,
    "layer-6": layers.$layer-6,
    "layer-7": layers.$layer-7,
    "layer-8": layers.$layer-8,
    "layer-9": layers.$layer-9,
    "layer-10": layers.$layer-10
);

// @each loops for creating classes
// Colours
@each $colour, $i in $colours {
	.colour-text-#{$colour} {
		color: $i;
	}	
	.colour-bg-#{$colour} {
		background-color: $i;
	}	
}
// Fonts
@each $fontSize, $i in $fontSizes {
	.font-size-#{$fontSize} {
		font-size: $i;
	}	
}
@each $fontWeight, $i in $fontWeights {
	.font-weight-#{$fontWeight} {
		font-weight: $i;
	}	
}
// Colours
@each $colour, $i in $colours {
	.colour-text-#{$colour} {
		color: $i;
	}	
	.colour-bg-#{$colour} {
		background-color: $i;
	}	
}
// Shadows
@each $shadow, $i in $shadows {
	.#{$shadow} {
		box-shadow: $i;
	}	
}
// Layers(z-index)
@each $layer, $i in $layers {
	.#{$layer} {
		position: relative;
		z-index: $i;
	}	
}
// Padding + margins
@each $size in $sizes {
    @each $selector in $selectors{
        @each $side in $sides {
            .#{nth($selector, 1)}#{nth($side, 1)}-#{nth($size, 1)} {
                #{nth($selector, 2)}#{nth($side, 2)}: nth($size, 2);
            }
        }
    }
}
@each $size in $sizes {
	@each $selector in $selectors {
		.#{nth($selector, 1)}v-#{nth($size, 1)} {
			#{nth($selector, 2)}-bottom: nth($size, 2); 
			#{nth($selector, 2)}-top: nth($size, 2); 
		}
		.#{nth($selector, 1)}h-#{nth($size, 1)} {
			#{nth($selector, 2)}-left : nth($size, 2); 
			#{nth($selector, 2)}-right : nth($size, 2); 
		}
	}
}

@each $tenth, $i in $tenths {
	.opacity-#{$tenth} {
		opacity: $i;
	}
}

@each $breakpoint, $i in $breakpoints {
	.display-#{$breakpoint}-none {
		@media screen and (min-width: $i){
			display: none;
		}
	}
	.display-#{$breakpoint}-block {
		@media screen and (min-width: $i){
			display: block;
		}
	}
	.display-#{$breakpoint}-flex {
		@media screen and (min-width: $i){
			display: flex;
		}
	}
}