@use './styles/colours';
@use './styles/borders';
@use './styles/spacing';

.page {
    &.pin-active-mode {
        .footnoteTarget {
            cursor: url('../public/pin.svg'), auto;
            outline: borders.$border-thick-opaque-primary !important;
            border-radius: spacing.$xs;

            &:hover {
                border-radius: spacing.$xs;
                outline: borders.$border-thick-primary !important;
                cursor: url('../public/pin.svg'), auto;
            }
        }
    }

    .footnoteTarget {
        outline: borders.$border-thick-transparent;
        border-radius: spacing.$xs;
        
        &.located-footnote {
            border-radius: spacing.$xs;
            outline: borders.$border-thick-primary;
        }

        &.outline-fade-out {
            -webkit-transition: outline 500ms ease-out;
            -moz-transition: outline 500ms ease-out;
            -o-transition: outline 500ms ease-out;
            transition: outline 500ms ease-out;
        }
    }
}