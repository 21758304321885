@use "../styles/colours";
@use "../styles/borders";
@use "../styles/layout/layers";
@use "../styles/typography";
@use "../styles/mixins";
@use "../styles/spacing";
@use "../styles/sizing";
@use "../styles/shadows";
@use "../styles/animations";

.overlay-wrapper {
	@include mixins.flex-center;
	@include mixins.fullscreen(fixed);
	background-color: rgba(colours.$bg-neutral-dark, 0.5);
	z-index: layers.$layer-10;
	padding: spacing.$xl;
	animation: animations.$transition-fade-in;
	padding-top: 24px;
	padding-bottom: 100px;
	opacity: 0;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-x: hidden;

	:-webkit-scrollbar {
		display: none; /* Hide scrollbar for Chrome, Safari and Opera */
	  }
	-ms-overflow-style: none;  /* IE and Edge */
  	scrollbar-width: none;  /* Firefox */
}