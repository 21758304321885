@use "../../styles/components/inputs";
@use "../../styles/mixins";
@use "../../styles/spacing";
@use "../../styles/colours";
@use "../../styles/typography";
@use "../../styles/animations";

$colour-range-warning: colours.$warning;

.slider-input-container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4px;
    height: 8px;
    z-index: 1;

    .popup-outer {
        width: 100%;
        position: relative;
        .popup-trigger {
            position: relative;
        }
        top: -4px
    }



    .slider-input-tooltip {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .slider-input {
        background-color: colours.$primary;
        border-radius: 10px;
        width: 3px;
        height: 8px;
        position: absolute;

    }

    .hobson-logo-slider-input {
        width: 20px;
        height: 20px;
        cursor: default;
    }

    .slider-input-variance {
        position: absolute;
        height: 8px;
        background-color: colours.$primary;
        border-radius: 4px;
        opacity: 0.2;
        width: 100%;
        // left: 0;
        // top: 50%;
        // transform: translateY(-50%);
    }

    &.warning {
        .slider-input {
            background-color: $colour-range-warning;
        }

        .slider-input-variance {
            background-color: $colour-range-warning;
        }
    }
}