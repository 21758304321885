@use "../../../styles/colours";
@use "../../../styles/sizing";
@use "../../../styles/spacing";
@use "../../../styles/borders";
@use "../../../styles/animations";
@use "../../../styles/mixins";
@use "../../../styles/layout/layers";
@use "../../../styles/shadows";
@use "../../../styles/typography";

.model-portfolio-banner {
	display: flex;
	align-items: center;
	height: 100%;
	gap: spacing.$s;
}

.model-portfolio-banner-title {
	font-size: typography.$font-size-header-3;
}

.model-portfolio-banner-icon {
	color: colours.$primary;
	height: typography.$font-size-header-3;
	width: typography.$font-size-header-3;
	transform: translateY(-1px);

	&.small {
		height: typography.$font-size-m;
		width: typography.$font-size-m;
	}
}

.model-portfolio-class-name {
	&:hover {
		.model-portfolio-class-edit-icon {
			display: block;
		}
	}
}

.model-portfolio-class-edit-icon {
	display: none;
	color: colours.$primary;
	height: spacing.$m;
	width: spacing.$m;
	margin-left: spacing.$s;
}

.model-portfolio-class-save-icon {
	display: none;
	color: colours.$primary;
	height: spacing.$m;
	width: spacing.$m;
}

.model-portfolio-class-input {
	border: none;
	background: none;
	font-size: spacing.$m;
	width: fit-content;
	font-family: typography.$font-family-primary;
	color: colours.$neutral-dark;

	&:focus-visible {
		outline: none;
		outline-offset: 0;
	}
}

.model-portfolio-add-class-row {
	display: flex;
	height: spacing.$xxl;
	border-bottom: borders.$border-default;
	background-color: colours.$neutral-blue;
	align-items: center;
	padding-left: spacing.$s;
	gap: spacing.$s;
	color: colours.$neutral-dark;
	max-width: 300px;
	cursor: pointer;

	&:hover {
		background-color: rgba(colours.$neutral-blue, 0.5);
	}
}

.model-portfolio-edit-asset-weighting {
	display: none;
	color: colours.$primary;
	height: spacing.$m;
	width: spacing.$m;
	margin-left: spacing.$s;
}

.model-portfolio-asset-weighting {

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.model-portfolio-asset-weighting-edit-percentage-sign {
		position: absolute;
		top: 50%;
		right: spacing.$xl;
		transform: translateY(-50%);
	}

	&:hover {
		.model-portfolio-edit-asset-weighting {
			display: block;
		}
	}

	.model-portfolio-edit-weighting-edit-input-background {
		width: 75%;

		input {
			background-color: colours.$neutral-light;
		}
	}
}

.model-portfolio-ticker-name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 150px;
	margin: 0px 10px;
}

.model-portfolio-ticker-detail-name {
	font-size: typography.$font-size-popup-menu-header;
	color: colours.$neutral-dark-40;

	&.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.model-portfolio-icons {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 66px;
	margin: auto;
	white-space: nowrap;
}

.model-portfolio-right-align {
	width: 90%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.model-portfolio-recommendation {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.model-portafolio-banner-create-grouping {
	.detail-field-wrapper {
		.text-input-wrapper {
			.text-input {
				height: spacing.$xl;
			}
		}
	}
}

.range-number-input-container {
	display: flex;
	gap: spacing.$m;

	input[type="number"] {
		-moz-appearance: textfield; // Firefox
		appearance: textfield; // Safari and Chrome
	}

	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.range-number-input {
	height: spacing.$xl;
	padding: spacing.$s;
	background-color: colours.$input-grey;
	border: borders.$border-grey;
	border-radius: spacing.$xs;
	font-family: typography.$font-family-primary;
	color: colours.$neutral-dark;

	&:focus-within {
		border: borders.$border-primary;
		outline: none
	}
}

.model-tr-edit-mode {
	td {
		border-top: none !important;
		border-bottom: none !important;
		background-color: colours.$neutral-light !important;
		// padding-top: spacing.$s !important;
		// padding-bottom: spacing.$s !important;
	}

	outline: borders.$border-thick-transparent;
	border-top: borders.$border-default;
	border-bottom: borders.$border-default;
	border-right: borders.$border-transparent;
	border-left: borders.$border-transparent;

	&.row-padding-s {
		td {
			padding-top: spacing.$s !important;
			padding-bottom: spacing.$s !important;
		}
	}

	&:hover {
		outline: borders.$border-primary !important;
		border-right: borders.$border-primary !important;
		border-left: borders.$border-primary !important;
		border-top: none;
		border-bottom: none;
		background-color: colours.$neutral-light;

		td {
			border-top: none !important;
			border-bottom: none !important;
			background-color: colours.$neutral-light;
		}
	}

	&:last-of-type {
		&:hover {
			td {
				border-bottom: borders.$border-primary !important;
			}
		}
	}
}

#model-portfolio-table {
	&.table {

		tr {
			outline: borders.$border-thick-transparent;
			border-right: borders.$border-transparent;
			border-left: borders.$border-transparent;

			td {
				height: 49px;
			}
		}
	}
}

.flex-align-right {
	display: flex;
	justify-content: right;
}

.helper-text {
	color: colours.$neutral-dark-40;
}

.logo-replacement {
	width: 40px;
	height: 40px;
	border-radius: spacing.$xs;
	background-color: colours.$neutral-dark-10;
	color: colours.$neutral-dark-40;
	font-size: typography.$font-size-header-3;
	font-weight: typography.$font-weight-medium;
	padding: spacing.$s;
	display: flex;
	align-items: center;
	justify-content: center;
}

.div-row {
	display: flex;
	width: 100%;
	max-width: 1400px;
	min-width: 1000px;
	align-items: center;
	border-bottom: borders.$border-default;
	background-color: colours.$neutral-light;

	.div-th {
		padding: spacing.$m spacing.$s;
	}

	.div-td {
		padding: spacing.$m spacing.$s;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}

	&:hover {
		background-color: colours.$input-grey;
	}
}