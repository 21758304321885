@use "../../../styles/colours";

.radio-input-field {
	margin-right: 12px;
	cursor: pointer;

	svg, .material-icons {
		width: 18px;
		margin-right: 3px;

		&.invalid-radio-input {
			color: colours.$danger;
		}
	}

	&:last-of-type {
		margin-right: 0;
	}
}
