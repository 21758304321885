@use "../styles/colours";
@use "../styles/spacing";
@use "../styles/mixins";
@use "../styles/typography";

.editor-page-content {
	@include mixins.hide-scrollbars;
	grid-area: page-content;
	background-color: colours.$bg-editor-grey;
	padding: spacing.$l;
	padding-bottom: spacing.$xl;
	width: 100%;
	height: inherit;
	overflow-y: auto;

	.page {
		&:first-of-type {
			margin-top: 0;
		}
	}

	.pinning-modal {
		background-color: rgba(colours.$bg-neutral-dark, 0.5);
		height: 100vh;
		width: 100%;
		position: absolute;
		top: 3rem;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		.pinning-message-block {
			opacity: 1;
			text-align: center;
			font-weight: 600;
			padding-top: 0;

			p {
				color: colours.$bg-neutral-light;
				font-size: typography.$font-size-header-3;
			}
		}
	}
	&:hover {
		.pinning-modal {
			display: none;
		}
	}
}

.builder-page__section {
	height: 100%;
	overflow: auto;
	position: relative;

	.builder-page__pdf-preview, embed {
		width: 100%;
		height: 100%;
	}

	embed {
		position: absolute;
		top: 0px;
		left: 0px;
		&.over {
			z-index: 30;
		}
		&.under {
			z-index: 20;
		}
	}
}

.builder-page__loading-overlay {
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	position: absolute;
	background-color: colours.$bg-editor-grey;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	z-index: 100;

	&.show-overlay {
		visibility: visible;
		opacity: 1;
	}
}

.builder-page__pdf-preview {
	background-color: colours.$bg-editor-grey;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 4px dashed colours.$bg-editor-grey;
	padding: 40px;
	h4 {
		opacity: 0.3;
	}
}

.publish-failure-alert {
	width: 100%
}

.publish-failure-list {
	padding: 1rem 0 0 2rem
}

.publish-failure-list-item {
	padding-bottom: 0.5rem;
}