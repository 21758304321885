@use "../../styles/components/inputs";
@use "../../styles/typography";
@use "../../styles/animations";
@use "../../styles/spacing";

.text-area-field {
	background-color: inputs.$colour-bg-default;
	border: inputs.$border-default;
	border-radius: inputs.$border-radius-default;
	color: inputs.$colour-text-default;
	font-size: inputs.$font-size-default;
	padding: inputs.$textarea-padding-default;
	height: inputs.$textarea-size-height-default;
	font-family: typography.$font-family-primary;
	transition: background-color animations.$transition-default;
	outline: none;
	width: 100%;

	&::placeholder{
		color: inputs.$colour-text-default-placeholder;
		transition: animations.$transition-default;
	}
	
	&:hover{
		
		&::placeholder{
			color: inputs.$colour-text-default-placeholder-hover;
			
		}
	}
	
	&:focus{
		color: inputs.$colour-text-default-focused;
		background-color: inputs.$colour-bg-default-focused;
		border: inputs.$border-default-focused;
		box-shadow: inputs.$shadow-default-focused;
		
		&::placeholder{
			color: inputs.$colour-text-default-placeholder-focused;	
		}
		
		.text-input-icon{
			color: inputs.$colour-icon-default-focused;
		}
	}

	&:focus-visible{
		outline: none;
		border: inputs.$border-default-focused;
		box-shadow: inputs.$shadow-default-focused;
	}
}

.text-area-container {
	width: 100%;
}