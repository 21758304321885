@use "../../styles/colours";
@use "../../styles/sizing";
@use "../../styles/typography";
@use "../../styles/spacing";
@use "../../styles/mixins";

.link-container{
	@include mixins.flex-center;
	width: sizing.$xl;
	height: sizing.$xl;
	border-radius: 4px;
	padding: 0.5rem;
	cursor: pointer;
	position: relative;

	svg, i {
		color: colours.$text-neutral-dark-50;
		&.material-downloaded-icon {
			transform: scale(1.2);
			fill: colours.$text-neutral-dark-50;
		}
		width: sizing.$icon-m;
		height: sizing.$icon-m;
	}

	
	&:hover{
		background-color: colours.$bg-neutral-blue;
	}
	
	&.variant-danger {
		background-color: colours.$bg-danger-light;
		svg, i {
			color: colours.$bg-danger-dark;
			&.material-downloaded-icon {
				fill: colours.$bg-danger-dark;
			}
		}
		&:hover {
			background-color: colours.$bg-danger-light;
		}
		
	}

	&.variant-active {
		svg, i {
			color: colours.$primary;
			&.material-downloaded-icon {
				fill: colours.$primary;
			}
		}
	}

	&.variant-positive {
		background-color: colours.$bg-positive-light;
		svg, i {
			color: colours.$bg-positive-dark;
			&.material-downloaded-icon {
				fill: colours.$bg-positive-dark;
			}
		}
		&:hover {
			background-color: colours.$bg-positive-light;
		}
	}

	.icon-counter-wrapper {
		background-color: colours.$bg-neutral-blue;
		border: 2px solid colours.$neutral-light;
		color: colours.$neutral-dark-70;
		font-weight: typography.$font-weight-bold;
		position: absolute;
		font-size: 10px;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		right: -12px;
		top: -10px;
		box-sizing: content-box;
	}

	&.current-route {
		.icon-counter-wrapper {
			background-color: colours.$primary;
			color: colours.$neutral-light;
			&.material-downloaded-icon {
				fill: colours.$neutral-light;
			}
		}
	}
}

.current-route{
	background-color: colours.$bg-neutral-blue;
	
	svg, i {
		color: colours.$text-primary;
		&.material-downloaded-icon {
			fill: colours.$text-primary;
		}
	}
}