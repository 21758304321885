@use "../../../styles/spacing";
@use "../../../styles/borders";
@use "../../../styles/typography";
@use "../../../styles/colours";
@use "../../../styles/mixins";


.custom-font-wrapper {
	border: borders.$border-default;
	border-radius: 4px;
	background-color: #fff;
	border-color: colours.$button-grey;
}

.cf-header {
	display: flex;
	justify-content: space-between;
	border: 4px;
	padding: 12px;
	background-color: colours.$button-grey;
	font-weight: typography.$font-weight-medium;
	color: colours.$neutral-dark;
}

.cf-name {
	@include mixins.truncate(98%);
}

.cf-delete-icon {
	color: colours.$neutral-dark-30;
	cursor: pointer;
	
	&:hover {
		color: colours.$danger;
	}
}

.cf-expand-icon {
	color: colours.$neutral-dark-30;
	cursor: pointer;
	
	&:hover {
		color: colours.$primary;
	}
}

.cf-inner {
	padding: 11px 16px;
	display: flex;
	justify-content: start;
	gap: 0.5rem;
	flex-direction: column;

	.file-drop {
		width: 100%;
	}

	.dropzone {
		min-height: 40px;
		padding: 0.5rem;
		gap: 0.5rem;
		width: 100%;

		svg {
			width: 1rem;
		}
		
		.dropzone-message {
			font-size: typography.$font-size-s;
		}
	}
}

.cf-block {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.75rem;
	

	.block-header {
		display: flex;
		align-items: center;
		font-weight: typography.$font-weight-medium;
		gap: 0.25rem;
	}
}
