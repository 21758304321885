@use "../../../styles/colours";
@use "../../../styles/mixins";
@use "../../../styles/spacing";
@use "../../../styles/borders";
@use "../../../styles/layout/layers";


.page-content-banner {
	padding-top: spacing.$l;
	grid-area: page-banner;
	z-index: layers.$layer-2;
	display: flex;
	align-items: flex-start;

	&.gap-xs {
		gap: spacing.$xs;
	}
	&.gap-s {
		gap: spacing.$s;
	}
	&.gap-m {
		gap: spacing.$m;
	}
	&.gap-l {
		gap: spacing.$l;
	}
	&.gap-xl {
		gap: spacing.$xl;
	}
	&.gap-xxl {
		gap: spacing.$xxl;
	}
}

.page-content-banner-divider {
	border-bottom: borders.$border-default;
}

.page-content-justified-between {
	justify-content: space-between;
}

.page-content-justified-center {
	justify-content: center;
}

.page-content-justified-evenly {
	justify-content: space-evenly;
}

.page-content-banner-fixed {
	position: fixed;
    top: 3rem;
	z-index: 20;
    width: 100%;
    padding-bottom: spacing.$l;
	padding-top: spacing.$l;
    background: #fff;
}